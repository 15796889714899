import * as MUTATION_TYPES from '../mutation-types'
import http from '../../http'
import { DateTime } from 'luxon'

// initial state
const state = {
  news: [],
}

// getters
const getters = {
  getterNews: state =>
    state.news
      .slice()
      .sort(
        (a, b) => DateTime.fromISO(b.createdAt) - DateTime.fromISO(a.createdAt)
      ),

  getterNewsByTags: state =>
    state.news.reduce((out, e) => {
      e.tags.forEach(tag => {
        if (out.hasOwnProperty(tag)) out[tag] = [e, ...out[tag]]
        else out[tag] = [e]
      })

      return out
    }, {}),
}

// actions
const actions = {
  /**
   * Get list user activities devices
   *
   * @param {Object} form
   * @param {String} form.code
   * @param {String} form.password
   * @param {Object} paginationOptions
   * @param {String} paginationOptions.page
   */
  getNews({ commit }, paginationOptions) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/news/pagination', paginationOptions)
        .then(({ data }) => {
          commit(MUTATION_TYPES.NEWS.INIT_NEWS, data.docs)
          resolve(data)
        })
        .catch(reject)
    })
  },

  // getNewsById({commit}, id) {
  //   return new Promise((resolve, reject) => {
  //     http.api
  //       .get('/news/' + id)
  //       .then(({data}) => {
  //         commit()
  //       })
  //   })
  // }
}

// mutations
const mutations = {
  /**
   * Initial news list
   * @param {Array} list
   */
  [MUTATION_TYPES.NEWS.INIT_NEWS](state, list) {
    state.news = list
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
