import regression from 'trading-vue-js/src/stuff/linreg'

export const PRICING_MODULE = 'Pricing'
import http from '../../http'
import data from 'bootstrap/js/src/dom/data'

export const PRICING_MUTATIONS_TYPE = {
  SET_PRICING: 'SET_PRICING',
}

const state = {}

const mutations = {}

const actions = {
  getPricing() {
    return new Promise((resolve, reject) => {
      http.api
        .get('/prices')
        .then(data => {
          return resolve(data)
        })
        .catch(err => reject(err))
    })
  },
  sendInvest(_, form) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/prices/user', form)
        .then(data => resolve(data))
        .catch(err => reject(err))
    })
  },
}

export const PricingVuex = {
  namespaced: true,
  state,
  mutations,
  actions,
}
