import { DateTime } from 'luxon'

import * as MUTATION_TYPES from '../mutation-types'
import http from '../../http'

// initial state
const state = {
  availablePairs: [],
  tradablePairs: [],
  currencies: [],
  futuresPrices: [],
  markers: [],

  balanceStats: undefined,

  favoritePairs: (localStorage.getItem('favoritePairs') || '')
    .split(',')
    .filter(e => e),

  selectedPairName: localStorage.getItem('selectedPairName') || 'BTCUSDT',
}

// getters
const getters = {
  getterFuturesPrices: state =>
    state.futuresPrices.reduce((out, e) => {
      out[e.name] = e
      return out
    }, {}),

  getterSelectedPair: state =>
    state.futuresPrices.find(e => e.name == state.selectedPairName),

  getterSelectedPairMarkers: state =>
    state.markers.filter(e => e.pair == state.selectedPairName).slice(),

  getterPairsMarkersByLimit: state => limit =>
    state.markers
      .slice()
      .sort(
        (a, b) => DateTime.fromISO(a.createdAt) - DateTime.fromISO(b.createdAt)
      )
      .slice(-limit),

  getterPairsMarkers: state =>
    state.markers
      .slice()
      .sort(
        (a, b) => DateTime.fromISO(a.createdAt) - DateTime.fromISO(b.createdAt)
      )
      .reduce((res, e) => {
        if (res[e.pair]) res[e.pair].push(e)
        else res[e.pair] = [e]

        res[e.pair] = res[e.pair].slice(-9)

        return res
      }, {}),
}

// actions
const actions = {
  /**
   * Get users list with pagination params
   */
  getAppBalanceWithPagination(_, pagination) {
    return new Promise((resolve, reject) => {
      http.api
        .post('app-balance/pagination', pagination)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  getBalanceStats({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('app-balance/stats')
        .then(({ data }) => {
          commit(MUTATION_TYPES.FINANCE.INIT_BALANCE_STATS, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getAvailablePairs({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('finance/pairs/available')
        .then(({ data }) => {
          commit(MUTATION_TYPES.FINANCE.INIT_AVAILABLE_PAIRS, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getTradablePairs({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('finance/pairs/tradable')
        .then(({ data }) => {
          commit(MUTATION_TYPES.FINANCE.INIT_TRADABLE_PAIRS, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Get list of currencies
   */
  getCurrencyList({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/finance/currencies')
        .then(({ data }) => {
          commit(MUTATION_TYPES.FINANCE.INIT_CURRENCIES, data.currencies)
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Get list markers by pair name
   *
   * @param {Object} form
   * @param {Object} form.pair currency pair name like BTCUSDT
   * @param {Object} form.dateStart timestamp
   * @param {Object} form.dateEnd timestamp
   */
  getMarkersList({ commit }, form) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/bot/markers', form)
        .then(({ data }) => {
          commit(MUTATION_TYPES.FINANCE.INIT_MARKERS, data.list)
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Get list candles by pair name and period
   *
   * @param {Object} form
   * @param {String} form.name currency pair name like "BTCUSDT"
   * @param {String} form.period string period like "1m"
   */
  getLastCandles(_, form) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/finance/candles/last', { params: form })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Get list candles for 24-h stats
   *
   * @param {Object} form
   * @param {String} form.name currency pair name like "BTCUSDT"
   */
  getDayCandles(_, form) {
    return new Promise((resolve, reject) => {
      http.api
        .get(`/finance/candles/day/${form.pair}`)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [MUTATION_TYPES.FINANCE.INIT_BALANCE_STATS](state, data) {
    state.balanceStats = data
  },

  [MUTATION_TYPES.FINANCE.INIT_AVAILABLE_PAIRS](state, list) {
    state.availablePairs = list
  },

  [MUTATION_TYPES.FINANCE.INIT_TRADABLE_PAIRS](state, list) {
    state.tradablePairs = list
  },

  /**
   * Initial currencies list
   * @param {Array} list
   */
  [MUTATION_TYPES.FINANCE.INIT_CURRENCIES](state, list) {
    state.currencies = list
  },

  /**
   * Initial futures prices
   * @param {Array} list
   */
  [MUTATION_TYPES.FINANCE.INIT_FUTURES_PRICES](state, list) {
    state.futuresPrices = list
  },

  /**
   * Toggle favorite currency pair
   * @param {String} name
   */
  [MUTATION_TYPES.FINANCE.TOGGLE_FAVORITE_PAIR](state, name) {
    let index = state.favoritePairs.indexOf(name)

    if (index > -1) state.favoritePairs.splice(index, 1)
    else state.favoritePairs.push(name)

    localStorage.setItem('favoritePairs', state.favoritePairs)
  },

  /**
   * Select to view currency pair
   * @param {String} name
   */
  [MUTATION_TYPES.FINANCE.UPDATE_SELECTED_PAIR](state, name) {
    state.selectedPairName = name
    localStorage.setItem('selectedPairName', name)
  },

  /**
   * Init bot markers
   * @param {Array | Object} data
   */
  [MUTATION_TYPES.FINANCE.INIT_MARKERS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e._id)

    state.markers = [
      ...state.markers.filter(e => !ids.includes(e._id)),
      ...list,
    ]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
