import Store from '../../store'
import * as MUTATION_TYPES from '../../store/mutation-types'

export default {
  'futures-prices'({ list }) {
    // console.log(MUTATION_TYPES)
    Store.commit(MUTATION_TYPES.FINANCE.MOD.INIT_FUTURES_PRICES, list)
  },

  'earn-profit'({ list }) {
    Store.commit(MUTATION_TYPES.USER.MOD.INIT_ME_EARN_DATA, list)
  },

  'update-markers'({ list }) {
    Store.commit(MUTATION_TYPES.FINANCE.MOD.INIT_MARKERS, list)
  },

  'update-user'(data) {
    Store.commit(MUTATION_TYPES.USER.MOD.INIT_USER, data)
  },

  'update-app-balance-stats'(data) {
    Store.commit(MUTATION_TYPES.FINANCE.MOD.INIT_BALANCE_STATS, data)
  },
}
