import http from '../../http'

// initial state
const state = {}

// getters
const getters = {}

// actions
const actions = {
  /**
   * Get list reviews with pagination params
   *
   * @param {Object} paginationOptions
   * @param {String} paginationOptions.page
   */
  getReviews(_, paginationOptions) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/reviews/pagination', { params: paginationOptions })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
