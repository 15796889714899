import Vue from 'vue'

import App from './components/App.vue'

import Notifications from 'vue-notification'
Vue.use(Notifications)

import VueLuxon from 'vue-luxon'
Vue.use(VueLuxon)

import { BootstrapVue } from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import router from './routes'
import store from './store'

import './filters'

import i18n from './i18n'
import { socketMixin } from './sockets'

Vue.config.productionTip = false
Vue.component('UiPreloader', () => import('./components/Preloader.vue'))
// Vue.prototype.$t = i18n.t

console.log('v: 2')

export const app = new Vue({
  render: h => h(App),
  i18n,
  router,
  store,

  mixins: [socketMixin],

  created() {},
}).$mount('#app')

// For dev
if (process.env.NODE_ENV !== 'production') window.app = app
