import http from '../../http'

export const MAIL_MODULE = 'Mail'

export const MAIL_MUTATION_TYPES = {
  SET_MAIL: 'SET_MAIL',
  SET_TEXT: 'SET_TEXT',
}

const state = {
  mail: '',
  text: '',
}

const mutations = {
  [MAIL_MUTATION_TYPES.SET_MAIL](state, payload) {
    state.mail = payload
  },
  [MAIL_MUTATION_TYPES.SET_TEXT](state, payload) {
    state.text = payload
  },
}

const actions = {
  sendMail({ state }) {
    return new Promise((resolve, reject) => {
      http.api
        .post('contact-us', { text: state.text, email: state.mail })
        .then(data => {
          return data
        })
        .catch(error => reject(error.data))
    })
  },
}

export const MailVuex = {
  namespaced: true,
  state,
  mutations,
  actions,
}
