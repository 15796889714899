import http from '../../http'

export const FAQ_MODULE = 'Faq'

export const FAQ_MUTATIONS_TYPE = {
  SET_FAQ: 'SET_FAQ',
}

const state = {
  faq: Array(),
}

const mutations = {
  [FAQ_MUTATIONS_TYPE.SET_FAQ](state, payload) {
    state.faq = payload
  },
}

const actions = {
  getFaqs({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .post('faq/pagination', {
          limit: 10,
          page: 1,
          offset: 1,
          sort: {},
          search: {},
        })
        .then(data => {
          const faqs = data.data.docs.map((e, i) => ({
            title: e.title,
            description: e.description,
            id: e._id,
          }))
          commit(FAQ_MUTATIONS_TYPE.SET_FAQ, faqs)
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
}

export const FaqVuex = {
  namespaced: true,
  state,
  mutations,
  actions,
}
