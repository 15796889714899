import http from '../../http'

export const NOTIFICATION_MODULE = 'Notifications'

export const NOTIFICATION_MUTATION = {
  GET_NOTIFICATION: 'GET_NOTIFICATION',
}

const state = {
  notification: [],
}

const mutations = {
  [NOTIFICATION_MUTATION.GET_NOTIFICATION](state, payload) {
    state.notification = payload
  },
}

const actions = {
  fetchNotifications({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .post('notifications/pagination', {
          limit: 10,
          page: 1,
          offset: 1,
          sort: {},
          search: {},
        })
        .then(({ data }) => {
          commit(NOTIFICATION_MUTATION.GET_NOTIFICATION, data.docs)
          resolve(data)
        })
        .catch(err => reject(err))
    })
  },
}

export const NotificationsVuex = {
  namespaced: true,
  state,
  mutations,
  actions,
}
