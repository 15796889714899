import * as MUTATION_TYPES from '../mutation-types'
import http from '../../http'
import { DateTime } from 'luxon'

// initial state
const state = {
  meActivities: [],
  meDevices: [],
}

// getters
const getters = {
  getterMeActivities: state =>
    state.meActivities.slice().sort(
      (a, b) => DateTime.fromISO(b.createdAt) - DateTime.fromISO(a.createdAt)
    ),

  getterMeDevices: state =>
    state.meDevices.slice().sort(
      (a, b) => DateTime.fromISO(b.createdAt) - DateTime.fromISO(a.createdAt)
    ),
}

// actions
const actions = {
  /**
   * Get list user activities devices
   *
   * @param {Object} form
   * @param {String} form.code
   * @param {String} form.password
   */
  getMeActivity({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/security/activity')
        .then(({ data }) => {
          commit(MUTATION_TYPES.SECURITY.INIT_ME_ACTIVITIES, data.activities)
          commit(MUTATION_TYPES.SECURITY.INIT_ME_DEVICES, data.devices)
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  /**
   * Initial list user activities
   * @param {Array} list
   */
  [MUTATION_TYPES.SECURITY.INIT_ME_ACTIVITIES](state, list) {
    state.meActivities = list
  },

  /**
   * Initial list user devices
   * @param {Array} list
   */
  [MUTATION_TYPES.SECURITY.INIT_ME_DEVICES](state, list) {
    state.meDevices = list
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
