import Vue from 'vue'
import getEmoji from 'get-random-emoji'

Vue.filter('currency', function(value) {
  if(typeof value == 'string')
    value = Number(value)

  if (typeof value !== 'number')
    return value



  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })
  return formatter.format(value)
})

Vue.filter('tradeCurrency', function(value) {
  if (!value) return '0.00'
  return String(Number(value))
})

Vue.filter('hideCurrency', function(value, count = 4, hide = true) {
  if (!hide) return value

  return new Array(count)
    .fill(0)
    .map(e => getEmoji())
    .join(' ')
})
