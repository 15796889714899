class M_TYPE {
  constructor(module, obj) {
    for (const key in obj) {
      this[key] = obj[key]
    }

    this._module_ = module
  }

  // get MOD() {
  //   return Object.keys(this).reduce((res, key) => {
  //     if (key != 'MOD') res[key] = this._module_ + '/' + this[key]
  //   }, {})
  // }

  get MOD() {
    let res = {}
    for (let key in this) {
      if (key == 'MOD') continue
      res[key] = this._module_ + '/' + this[key]
    }

    return res
  }
}

export const USER = new M_TYPE('User', {
  INIT_USER: 'INIT_USER',
  INIT_ME_EARN_DATA: 'INIT_ME_EARN_DATA',
  INIT_PRICING_DATA: 'INIT_PRICING_DATA',
  INIT_CURRENCY: 'INIT_CURRENCY',
  INIT_ADDITIONAL_BALANCE: 'INIT_ADDITIONAL_BALANCE',
  INIT_WALLET: 'INIT_WALLET',
})

export const APP = new M_TYPE('App', {
  TOGGLE_USER_SIDEBAR: 'TOGGLE_USER_SIDEBAR',
  TOGGLE_HIDE_BALANCES: 'TOGGLE_HIDE_BALANCES',
  TOGGLE_USER_PAIR_STATS: 'TOGGLE_USER_PAIR_STATS',
  TOGGLE_USER_MOBILE_NAVBAR: 'TOGGLE_USER_MOBILE_NAVBAR',
  TOGGLE_USER_MOBILE_MENU: 'TOGGLE_USER_MOBILE_MENU',
  UPDATE_PERIOD_CANDLES: 'UPDATE_PERIOD_CANDLES',
})

export const NEWS = new M_TYPE('News', {
  INIT_NEWS: 'INIT_NEWS',
})

export const FINANCE = new M_TYPE('Finance', {
  INIT_TRADABLE_PAIRS: 'INIT_TRADABLE_PAIRS',
  INIT_AVAILABLE_PAIRS: 'INIT_AVAILABLE_PAIRS',
  INIT_CURRENCIES: 'INIT_CURRENCIES',
  INIT_FUTURES_PRICES: 'INIT_FUTURES_PRICES',
  TOGGLE_FAVORITE_PAIR: 'TOGGLE_FAVORITE_PAIR',
  UPDATE_SELECTED_PAIR: 'UPDATE_SELECTED_PAIR',
  INIT_MARKERS: 'INIT_MARKERS',
  INIT_BALANCE_STATS: 'INIT_BALANCE_STATS',
})

export const SECURITY = new M_TYPE('Security', {
  INIT_ME_ACTIVITIES: 'INIT_ME_ACTIVITIES',
  INIT_ME_DEVICES: 'INIT_ME_DEVICES',
})

export const REFERRAL = new M_TYPE('Referral', {
  INIT_ME_INFO: 'INIT_ME_INFO',
})
