<template>
  <div v-if="loadMe" class="app-page-preloader"></div>
  <router-view v-else></router-view>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',

  data: () => ({
    loadMe: false,
  }),

  computed: {
    ...mapState('User', ['me']),
  },

  async mounted() {
    if (this.me) {
      this.getMeEarnData()
      setTimeout(() => {
        this.$socket.emit('user-connect', this.me._id)
      }, 1000)
    }
  },

  methods: {
    ...mapActions('User', ['getMeEarnData']),
  },
}
</script>

<style src="@assets/scss/default.scss" lang="scss"></style>
