// import Vue from 'vue'
// import VueI18n from 'vue-i18n'
// import axios from 'axios'

// Vue.use(VueI18n)

// const loadedLanguages = []

// export const i18n = new VueI18n({
//   locale: process.env.VUE_APP_I18N_LOCALE,
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
//   silentTranslationWarn: process.env.NODE_ENV === 'production',
// })

// function setI18nLanguage(lang) {
//   i18n.locale = lang
//   axios.defaults.headers.common['Accept-Language'] = lang
//   document.querySelector('html').setAttribute('lang', lang)
//   return lang
// }

// export function loadLanguageAsync(lang) {
//   const url = `${process.env.VUE_APP_API_URL}/langs/code/${lang}`

//   if (loadedLanguages.includes(lang)) {
//     if (i18n.locale !== lang) setI18nLanguage(lang)
//     return Promise.resolve()
//   }

//   return axios.get(url).then(response => {
//     const { json } = response.data
//     const msgs = json ? JSON.parse(json) : {}
//     loadedLanguages.push(lang)
//     i18n.setLocaleMessage(lang, msgs)
//     setI18nLanguage(lang)
//   })
// }

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./langs', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  console.log('messages', messages)
  return messages
}

const vueI18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  // silentTranslationWarn: process.env.NODE_ENV === 'production',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages: loadLocaleMessages(),
})

export default vueI18n
