import { i18nMid, logMid } from '../middlewares'
import { middlewareGroup } from '../plugins/middleware'

export default [
  {
    component: () => import('../../layouts/global'),
    path: '/',
    redirect: { name: 'home' },
    children: middlewareGroup(
      [logMid, i18nMid],
      [
        {
          component: () => import('../../views/global/home'),
          path: '/',
          name: 'home',
        },
        {
          component: () => import('../../views/global/features'),
          path: '/features',
          name: 'features',
        },
        {
          component: () => import('../../views/global/contacts'),
          path: '/contact-us',
          name: 'contact',
        },
        {
          component: () => import('../../views/global/pricing'),
          path: '/pricing',
          name: 'pricing',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/pricing/mobilePage.vue'),
          path: 'pricing-mobile',
          name: 'pricing-mobile',
        },
        {
          component: () => import('../../views/global/exchange'),
          path: '/exchange',
          name: 'exchange',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/platform'),
          path: '/paper-trading',
          name: 'paper-trading',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/news'),
          path: '/news',
          name: 'news',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/news/NewsPage.vue'),
          path: '/news/:id',
          name: 'newsItem',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/affiliateProgram'),
          path: '/affiliate-program',
          name: 'affiliateProgram',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/review'),
          path: '/review',
          name: 'review',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/marketplace'),
          path: 'pro-traders',
          name: 'pro-traders',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/about'),
          path: 'about',
          name: 'about',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/terms'),
          path: 'terms',
          name: 'terms',
          meta: {
            showMobileMenu: true,
          },
        },
        {
          component: () => import('../../views/global/faq'),
          path: 'faq',
          name: 'faq',
          meta: {
            showMobileMenu: true,
          },
        },
      ]
    ),
  },
]
