import Vue from 'vue'

Vue.filter('intl', function(value) {
  let defCode =
    localStorage.getItem('defCode') || process.env.VUE_APP_DEFAULT_LANG

  if (typeof value === 'object' && value !== null) {
    return value[defCode]
  }

  console.warn(
    `deflang value must been deep object with langs keys, v: ${value}`
  )
  return value
})
