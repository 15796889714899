import Vue from 'vue'
import Vuex from 'vuex'

import AppModule from './modules/app'
import UserModule from './modules/user'
import SecurityModule from './modules/security'
import NewsModule from './modules/news'
import FinanceModule from './modules/finance'
import ReviewsModule from './modules/reviews'
import ReferralModule from './modules/referral'
import Langs from './modules/langs'
import { FAQ_MODULE, FaqVuex } from './modules/faq'
import { MAIL_MODULE, MailVuex } from './modules/mail'
import { PRICING_MODULE, PricingVuex } from './modules/pricing'
import { NOTIFICATION_MODULE, NotificationsVuex } from './modules/notification'

Vue.use(Vuex)

const Store = new Vuex.Store({
  modules: {
    App: AppModule,
    User: UserModule,
    Security: SecurityModule,
    News: NewsModule,
    Finance: FinanceModule,
    Reviews: ReviewsModule,
    Referral: ReferralModule,
    Langs: Langs,
    [FAQ_MODULE]: FaqVuex,
    [MAIL_MODULE]: MailVuex,
    [PRICING_MODULE]: PricingVuex,
    [NOTIFICATION_MODULE]: NotificationsVuex,
  },

  strict: true,
})

export default Store
