import { i18nMid, logMid, waitMeMid } from '../middlewares'
import { middlewareGroup } from '../plugins/middleware'

export default [
  {
    component: () => import('../../layouts/user'),
    path: '/user',
    redirect: { name: 'account' },
    children: middlewareGroup(
      [waitMeMid, logMid, i18nMid],
      [
        {
          component: () => import('../../views/global/account'),
          path: 'account',
          name: 'account',
        },
        {
          component: () => import('../../views/global/currency'),
          path: 'currency',
          name: 'currency',
        },
        {
          component: () => import('../../views/global/trade'),
          path: 'trade',
          name: 'trade',
        },
        {
          component: () => import('../../views/global/referral'),
          path: 'referral',
          name: 'referral',
        },
        {
          component: () =>
            import('../../layouts/user/Views/Settings/ResetPassword'),
          path: 'reset',
          name: 'reset',
        },
        {
          component: () =>
            import('../../layouts/user/Views/Dashboard/Cryptocurrency'),
          path: 'cryptocurrency',
          name: 'cryptocurrency',
        },
        {
          component: () => import('../../layouts/user/Views/Settings/Profile'),
          path: 'profile',
          name: 'profile',
        },
        {
          component: () =>
            import('../../layouts/user/Views/Dashboard/Deposits'),
          path: 'deposits',
          name: 'deposits',
        },
        {
          component: () => import('../../layouts/user/Views/Dashboard/Referal'),
          path: 'referal',
          name: 'referal',
        },
      ]
    ),
  },
]
