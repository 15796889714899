import Vue from 'vue'
import Router from 'vue-router'
import { MiddlewarePlugin } from './plugins/middleware'

import global from './groups/global'
import authRoutes from './groups/auth-routes'
import userRoutes from './groups/user-routes'
import mobileRoutes from './groups/mobile'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...global, ...authRoutes, ...userRoutes, ...mobileRoutes],
})

router.beforeEach((...args) => MiddlewarePlugin(...args, router))

export default router
