import * as MUTATION_TYPES from '../mutation-types'
import http from '../../http'
import { MAX_SHOW_EARNS } from '../../constants'
import { DateTime } from 'luxon'

// initial state
const state = {
  // Current auth user object
  me: undefined,
  meEarnData: [],
  pricing: [],
  wallets: [],
  currency: [],
  additionalBalance: [],
}

// getters
const getters = {
  getterMeBalance: state => state?.me?.balance || 0,
  getterMeEarnData: state =>
    state.meEarnData
      .slice()
      .sort(
        (a, b) => DateTime.fromISO(a.createdAt) - DateTime.fromISO(b.createdAt)
      )
      .slice(-MAX_SHOW_EARNS),
}

// actions
const actions = {
  getUserWallet({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/wallets/pagination', {
          limit: 100,
          page: 1,
          offset: 1,
          sort: {},
          search: {},
        })
        .then(({ data: { docs } }) => {
          commit(MUTATION_TYPES.USER.INIT_WALLET, docs)
          resolve(docs)
        })
        .catch(e => console.log(e))
    })
  },
  getAdditionalBalance({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/additional-balance', {
          limit: 100,
          page: 1,
          offset: 1,
          sort: {},
          search: {},
        })
        .then(({ data: { docs } }) => {
          commit(MUTATION_TYPES.USER.INIT_ADDITIONAL_BALANCE, docs)
          resolve(docs)
        })
        .catch(e => reject(e))
    })
  },
  getCurrencyList({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/available-currency')
        .then(({ data }) => {
          commit(MUTATION_TYPES.USER.INIT_CURRENCY, data)
          resolve(data)
        })
        .catch(e => reject(e))
    })
  },
  /**
   * Authorize user action
   *
   * @param {Object} form
   * @param {Object} form.username
   * @param {Object} form.password
   *
   * @returns {Promise} user object
   */
  signIn({ commit }, form) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/auth/sign-in', form)
        .then(({ data }) => {
          console.log(data)
          // Set jwt bearer token to general http instance
          http.setBearer(data.accessToken)

          commit(MUTATION_TYPES.USER.INIT_USER, data.user)

          resolve(data.user)
        })
        .catch(reject)
    })
  },

  /**
   * Create new account action
   *
   * @param {Object} form
   * @param {String} form.username
   * @param {String} form.password
   * @param {String} form.referral referral link, required, but may be empty string
   *
   * @returns {Promise} user object
   */
  signUp({ commit }, form) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/auth/sign-up', form)
        .then(({ data }) => {
          console.log(data)
          // Set jwt bearer token to general http instance
          http.setBearer(data.accessToken)

          commit(MUTATION_TYPES.USER.INIT_USER, data.user)

          resolve(data.user)
        })
        .catch(reject)
    })
  },

  /**
   * Sign out from current account
   * Clear all local storage data
   */
  signOut() {
    window.localStorage.clear()
    window.location.reload()
  },

  /**
   * Get current auth user action
   *
   * @returns {Promise} user object
   */
  getMe({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.me) return resolve(state.me)

      http.api
        .get('/users/me')
        .then(({ data }) => {
          commit(MUTATION_TYPES.USER.INIT_USER, data.user)

          resolve(data.user)
        })
        .catch(reject)
    })
  },

  /**
   * Update current auth user password
   *
   * @param {Object} form
   * @param {String} form.password
   * @param {String} form.newPassword
   */
  updateMePassword(_, form) {
    return new Promise((resolve, reject) => {
      http.api
        .put('/users/me/password', form)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Forgot password user by email
   *
   * @param {Object} form
   * @param {String} form.email
   */
  forgotPassword(_, form) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/auth/forgot-password', form)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Reset user password by code and login
   *
   * @param {Object} form
   * @param {String} form.code
   * @param {String} form.password
   */
  resetPassword({ commit }, form) {
    return new Promise((resolve, reject) => {
      http.api
        .post('/auth/reset-password', form)
        .then(({ data }) => {
          http.setBearer(data.accessToken)

          commit(MUTATION_TYPES.USER.INIT_USER, data.user)

          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Get me earn data
   */
  getMeEarnData({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/user-earn')
        .then(({ data }) => {
          commit(MUTATION_TYPES.USER.INIT_ME_EARN_DATA, data.list)
          resolve(data.list)
        })
        .catch(reject)
    })
  },

  getUserPrices({ commit }) {
    console.log('heare')
    return new Promise((resolve, reject) => {
      http.api
        .post('/prices/user/pagination', {
          limit: 10,
          page: 1,
          offset: 1,
          sort: {},
          search: {},
        })
        .then(({ data }) => {
          commit(MUTATION_TYPES.USER.INIT_PRICING_DATA, data.docs)
          resolve(data.docs)
        })
        .catch(error => reject(error))
    })
  },
}

// mutations
const mutations = {
  /**
   * Initial user wallet
   * @param {Array<Object>} walletData
   */
  [MUTATION_TYPES.USER.INIT_WALLET](state, walletData) {
    state.wallets = walletData
  },
  /**
   * Initial current user in this module state
   * @param {Object} userData
   */
  [MUTATION_TYPES.USER.INIT_USER](state, userData) {
    state.me = userData
  },
  /**
   * initial wallet
   * @param {Array<Object>} currencyData
   * */
  [MUTATION_TYPES.USER.INIT_CURRENCY](state, currencyData) {
    state.currency = currencyData
  },

  /**
   * Initial user profit earn
   * @param {Array | Object} data
   */
  [MUTATION_TYPES.USER.INIT_ME_EARN_DATA](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e._id)

    state.meEarnData = [
      ...state.meEarnData.filter(e => !ids.includes(e._id)),
      ...list,
    ]
  },
  /**
   * init pricing data
   * @param {Array<Object>} payload
   * */
  [MUTATION_TYPES.USER.INIT_PRICING_DATA](state, payload) {
    state.pricing = payload
  },
  /**
   * init additional balance data
   * @param {Array<Object>} payload
   * */
  [MUTATION_TYPES.USER.INIT_ADDITIONAL_BALANCE](state, payload) {
    state.additionalBalance = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
