import * as MUTATION_TYPES from '../mutation-types'
import http from '../../http'
import { DateTime } from 'luxon'

// initial state
const state = {
  meReferralInfo: undefined,
}

// getters
const getters = {}

// actions
const actions = {
  /**
   * Get current user referral data
   */
  getReferralInfo({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/referrals/info')
        .then(({ data }) => {
          commit(MUTATION_TYPES.REFERRAL.INIT_ME_INFO, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Get referral earns with pagination for current user
   */
  getReferralEarnsWithPagination(_, pagination) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/referrals/me-earns', { params: pagination })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },

  /**
   * Get referral earns with pagination for current user
   */
  getUserReferralsWithPagination(_, pagination) {
    return new Promise((resolve, reject) => {
      http.api
        .get('/referrals/me-referrals', { params: pagination })
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [MUTATION_TYPES.REFERRAL.INIT_ME_INFO](state, data) {
    state.meReferralInfo = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
