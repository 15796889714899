export default [
    {
        component: () => import('../../layouts/auth'),
        path: '/auth',
        redirect: { name: 'sign-in' },
        children: [
            {
                component: () => import('../../views/auth/signIn'),
                path: 'sign-in',
                name: 'sign-in'
            },
            {
                component: () => import('../../views/auth/signUp'),
                path: 'sign-up',
                name: 'sign-up'
            },
            {
                component: () => import('../../views/auth/forgotPassword'),
                path: 'forgot-password',
                name: 'forgot-password'
            },
            {
                component: () => import('../../views/auth/resetPassword'),
                path: 'reset-password/:code',
                name: 'reset-password'
            }
        ]
    }
]