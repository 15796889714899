export default [
  {
    component: () => import('../../layouts/mobile'),
    path: '/mobile',
    redirect: { name: 'trade-mobile' },
    children: [
      {
        component: () => import('../../views/global/trade/tradeMobile.vue'),
        path: 'trade-mobile',
        name: 'trade-mobile',
      },
    ],
  },
]
