import * as MUTATION_TYPES from '../mutation-types'
import http from '../../http'

const LANGS = {
  INIT_LANGS: 'SET_LANGS',
  INIT_AVAILIBLE: 'INIT_AVAILIBLE',
  SET_CURRENT_LANG: 'SET_CURRENT_LANG',
}

// initial state
const state = {
  langs: [],
  avalibleLangs: [],
  currentLang: localStorage.getItem('userLang') || '',
}

// getters
const getters = {
  getterLangsObj: state =>
    state.langs.reduce((sum, e) => {
      sum[e.codeLang] = e
      return sum
    }, {}),
}

// actions
const actions = {
  getLang({ commit }, lang) {
    return new Promise((resolve, reject) => {
      http.api
        .get(`langs/code/${lang}`)
        .then(({ data }) => {
          // commit(MUTATION_TYPES.LANGS.INIT_LANGS, data)
          resolve(data)
        })
        .catch(reject)
    })
  },

  getAvailableLangs({ commit }) {
    return new Promise((resolve, reject) => {
      http.api
        .get('langs/available')
        .then(({ data }) => {
          commit(LANGS.INIT_AVAILIBLE, data)
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  [LANGS.INIT_LANGS](state, data) {
    let list = Array.isArray(data) ? data : [data],
      ids = list.map(e => e._id)

    state.langs = [...state.langs.filter(e => !ids.includes(e._id)), ...list]
  },
  [LANGS.INIT_AVAILIBLE](state, data) {
    state.avalibleLangs = data
  },
  [LANGS.SET_CURRENT_LANG](state, data) {
    state.currentLang = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
